<template>
  <div class="page2">
    <div v-if="pageType == 1" class="bg" :style="{ backgroundColor: bg }">
      <div
        class="wow slideInLeft"
        data-wow-duration="1.5s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img class="bg-img" :src="imgObj.left" alt="网站SEO优化" />
      </div>

      <div
        class="right wow slideInRight"
        data-wow-duration="1.5s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img :src="imgObj.right" alt="网站SEO优化" />
      </div>
    </div>

    <!-- 电脑和树 -->
    <div
      v-if="pageType == 2 || pageType == 4"
      class="bg"
      :style="{ backgroundColor: bg }"
    >
      <div
        class="wow slideInLeft"
        data-wow-duration="1.5s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img class="bg-img" :src="imgObj.left" alt="天舜科技免费帮你做网站推广" />
        <div v-if="pageType == 2" class="gotoForm" @click="gotoForm()"></div>
      </div>

      <div
        class="right wow slideInRight right2"
        data-wow-duration="1.5s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img :src="imgObj.right" alt="降低企业网站运营成本" />
      </div>
    </div>

    <!-- 付费建网站 -->
    <div v-if="pageType == 3" class="bg" :style="{ backgroundColor: bg }">
      <div
        class="wow slideInLeft left3"
        data-wow-duration="1.5s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img class="bg-img" :src="imgObj.left" alt="免费在搜索引擎推广网站" />
        <div class="gotoForm" @click="gotoForm()"></div>
      </div>

      <div
        class="right wow slideInRight right3"
        data-wow-duration="1.5s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img :src="imgObj.right" alt="免费在搜索引擎推广网站" />
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" :show-close="false" width="29%">
      <div class="formTanku">
        <el-row>
          <el-col :sm="24" class="hidden-xs-only">
            <div class="h2 title">联系我们，制定您的专属运营方案</div>
            <div class="el-icon-close" @click="dialogVisible = false"></div>
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
              :rules="yanzhe"
              ref="dateForm"
            >
              <el-form-item label="姓名" size="medium" prop="signup_name">
                <el-input
                  v-model="formLabelAlign.signup_name"
                  placeholder="请填写您的姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" size="medium" prop="signup_mobile">
                <el-input
                  v-model="formLabelAlign.signup_mobile"
                  placeholder="请填写您的联系电话"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="sub" @click="submitForm('dateForm')">提交</div>
          </el-col>
          <el-col :xs="24" class="hidden-sm-and-up yd_right">
            <div class="h2 title">联系我们，制定您的专属运营方案</div>
            <div class="el-icon-close" @click="dialogVisible = false"></div>
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
              :rules="yanzhe"
              ref="ValidateForm"
            >
              <el-form-item label="姓名" size="medium" prop="signup_name">
                <el-input
                  v-model="formLabelAlign.signup_name"
                  placeholder="请填写您的姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" size="medium" prop="signup_mobile">
                <el-input
                  v-model="formLabelAlign.signup_mobile"
                  placeholder="请填写您的联系电话"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="sub" @click="submitForm('ValidateForm')">提交</div>
          </el-col>
        </el-row>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">提 交</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import api from "../assets/js/request/api";
export default {
  name: "page2",
  props: {
    imgObj: Object,
    bg: {
      default: "#FFFFFF",
    },
    pageType: {
      default: 1,
    },
  },
  data() {
    return {
      imgList: [""],
      dialogVisible: false,
      // 表单提交数据
      formLabelAlign: {
        signup_company: "",
        signup_name: "",
        signup_mobile: "",
        memo: "代运营",
        client_device: 1,
      },
      // 表单验证
      yanzhe: {
        //required 必填字段，即非空验证。如上面实例中的的非空验证，以及邮箱前边的必填符号*，就是这个参数的功劳。
        // pattern：正则表达式，如果需要验证手机号码之类，可以直接编写正则表达式配置到校验规则中，那么就不需要自己去校验了，由校验器自动校验：
        // min/max：判断数据大小范围，通常对数字大小范围做校验。对于字符串和数组类型，将根据长度进行比较：
        // whitespace：验证是否只有空格(如果没有该配置，则全空格的输入值也是有效的）。
        // messages: 未通过校验的提示信息：
        // validator：可以为指定字段自定义验证函数——这就相当于把前边配置的东西用js按照以前的方式编写验证逻辑了。虽然麻烦点，但是能实现比较复杂的业务逻辑判断。
        signup_name: [
          { required: true, message: "请填写您的姓名", trigger: "blur" },
        ],
        signup_mobile: [
          { required: true, message: "请填写您的联系电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "您填写的联系电话格式有误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    gotoForm() {
      this.dialogVisible = true;
    },
    // 提交表单
    submitForm(type) {
      this.$refs[type].validate(async (valid) => {
        if (valid) {
          this.dialogVisible = false;
          let res = await api.applyData(this.formLabelAlign);
          if (res.code == 200) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
          } else {
            this.$message.error(res.msg);
          }
          console.log(res, "------40");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },
};
</script>

<style lang="scss">
.page2 {
  .bg {
    position: relative;
    .bg-img {
      width: 100%;
    }
    .right {
      position: absolute;
      right: 0;
      top: 0;
      img {
        width: 100%;
      }
    }
    .gotoForm {
      position: absolute;
      top: 680px;
      left: 250px;
      z-index: 100;
      width: 300px;
      height: 50px;
      cursor: pointer;
    }
    .right2 {
      right: 100px;
      top: 120px;
      img {
        width: 950px;
        transition: 0.5s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }
    .left3 {
      position: absolute;
      left: 100px;
      top: 120px;
      z-index: 40;
      img {
        width: 950px;
        transition: 0.5s;
      }
      img:hover {
        transform: scale(1.2);
      }
      .gotoForm {
        left: 1110px;
        top: 550px;
      }
    }
    .right3 {
      position: inherit;
    }
  }

  //弹框
  .el-dialog {
    @media only screen and (max-width: 768px) {
      width: 80% !important;
    }
  }
  .el-dialog__body {
    padding: 12px;
  }
  .el-dialog__header {
    padding: 0px;
  }

  .formTanku {
    width: 530px;
    height: 500px;
    border: 1px dashed #999999;
    padding: 50px 32px 32px;
    position: relative;
    @media only screen and (max-width: 600px) {
      width: 100%;
      height: 43vh;
      padding: 30px 24px 24px;
    }
    @media only screen and (min-width: 600px) and (max-width: 768px) {
      width: 100%;
      height: 55vh;
      padding: 50px 32px 32px;
    }
    .title {
      padding-bottom: 32px;
      border-bottom: 1px solid #cfd1d5;
      margin-bottom: 32px;
    }
    .el-icon-close {
      position: absolute;
      right: -20px;
      top: -40px;
      font-size: 24px;
      @media only screen and (max-width: 600px) {
        right: -15px;
        top: -25px;
        font-size: 24px;
      }
      @media only screen and (min-width: 600px) and (max-width: 768px) {
        right: -20px;
        top: -40px;
        font-size: 24px;
      }
    }
    .el-form {
      .el-form-item {
        text-align: left;
        .el-form-item__label {
          font-size: 24px;
        }
        .el-form-item__content {
          .el-input {
            input {
              height: 50px;
              font-size: 18px;
            }
          }
        }
      }
    }
    .sub {
      font-size: 20px;
      margin-top: 60px;
      width: 100%;
      height: 50px;
      line-height: 50px;
      background-color: #366ce0;
      border-radius: 10px;
      color: #fff;
    }

    // 移动端
    .yd_right {
      @media only screen and (min-width: 768px) {
        border: 0px;
        height: 0px;
        margin: 0px;
        padding: 0px;
      }
      .title {
        width: 100%;
        padding-bottom: 32px;
        border-bottom: 1px solid #cfd1d5;
        margin-bottom: 32px;
        @media only screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
      .el-form {
        .el-form-item {
          text-align: left;
          .el-form-item__label {
            font-size: 60px;
            margin-bottom: 32px;
            margin-top: 80px;
          }
          .el-form-item__content {
            .el-input {
              input {
                height: 150px;
                font-size: 50px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      .sub {
        font-size: 20px;
        margin-top: 80px;
        width: 100%;
        height: 150px;
        line-height: 150px;
        background-color: #366ce0;
        border-radius: 10px;
        color: #fff;
      }
    }
  }
}
/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 600px) {
}

/* 小设备 (平板电脑和大型手机，600 像素及以上) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
}

/* 中型设备（平板电脑，768 像素及以上） */
@media only screen and (min-width: 768px) {
}

/* 大型设备（笔记本电脑/台式机，992 像素及以上） */
@media only screen and (min-width: 992px) {
}

/* 超大型设备（大型笔记本电脑和台式机，1200 像素及以上） */
@media only screen and (min-width: 1200px) {
}
</style>
