<template>
  <div class="page3">
    <div class="bg" :style="{ backgroundColor: bg }">
      <div
        class="wow slideInLeft"
        data-wow-duration="1.5s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img class="bg-img" :src="imgObj.left" alt="免费获取网站SEO运营优化方案" />
        <img class="bg-img2" :src="imgObj.leftIco" alt="免费获取网站SEO运营优化方案" />
      </div>

      <div
        class="right wow slideInRight"
        data-wow-duration="1.5s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <el-row>
          <el-col :sm="24" class="hidden-xs-only">
            <div class="h2 title">联系我们，制定您的专属运营方案</div>
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
              :rules="yanzhe"
              ref="dateForm"
            >
              <el-form-item label="姓名" size="medium" prop="signup_name">
                <el-input
                  v-model="formLabelAlign.signup_name"
                  placeholder="请填写您的姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" size="medium" prop="signup_mobile">
                <el-input
                  v-model="formLabelAlign.signup_mobile"
                  placeholder="请填写您的联系电话"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="sub" :class="ifSub ? '':'sub2'" @click="submitForm('dateForm')">提交</div>
          </el-col>
        </el-row>
      </div>
      <div class="yd_right">
        <el-row type="flex" justify="center">
          <el-col :xs="22" class="hidden-sm-and-up">
            <div class="h2 title">联系我们，制定您的专属运营方案</div>
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
              :rules="yanzhe"
              ref="ValidateForm"
            >
              <el-form-item label="姓名" size="medium" prop="signup_name">
                <el-input
                  v-model="formLabelAlign.signup_name"
                  placeholder="请填写您的姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" size="medium" prop="signup_mobile">
                <el-input
                  v-model="formLabelAlign.signup_mobile"
                  placeholder="请填写您的联系电话"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="sub" :class="ifSub ? '':'sub2'" @click="submitForm('ValidateForm')">提交</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../assets/js/request/api";
export default {
  name: "page3",
  props: {
    imgObj: Object,
    bg: "#fff",
  },
  data() {
    return {
      imgList: [""],
      ifSub: true,
      // 表单提交数据
      formLabelAlign: {
        signup_company: "",
        signup_name: "",
        signup_mobile: "",
        memo: "代运营",
        client_device: 1,
      },
      // 表单验证
      yanzhe: {
        //required 必填字段，即非空验证。如上面实例中的的非空验证，以及邮箱前边的必填符号*，就是这个参数的功劳。
        // pattern：正则表达式，如果需要验证手机号码之类，可以直接编写正则表达式配置到校验规则中，那么就不需要自己去校验了，由校验器自动校验：
        // min/max：判断数据大小范围，通常对数字大小范围做校验。对于字符串和数组类型，将根据长度进行比较：
        // whitespace：验证是否只有空格(如果没有该配置，则全空格的输入值也是有效的）。
        // messages: 未通过校验的提示信息：
        // validator：可以为指定字段自定义验证函数——这就相当于把前边配置的东西用js按照以前的方式编写验证逻辑了。虽然麻烦点，但是能实现比较复杂的业务逻辑判断。
        signup_name: [
          { required: true, message: "请填写您的姓名", trigger: "blur" },
        ],
        signup_mobile: [
          { required: true, message: "请填写您的联系电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "您填写的联系电话格式有误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 提交表单
    submitForm(type) {
      this.$refs[type].validate(async (valid) => {
        if (valid) {
          if(!this.ifSub) return
          this.ifSub = false;
          console.log(this.ifSub, "------129");
          let res = await api.applyData(this.formLabelAlign);
          if (res.code == 200) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
          } else {
            this.$message.error(res.msg);
          }
          this.ifSub = true;
          console.log(this.ifSub, "------140");
        } else {
          console.log(this.ifSub, "error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.page3 {
  .bg {
    position: relative;
    .bg-img {
      width: 100%;
    }
    .bg-img2 {
      position: absolute;
      left: 260px;
      top: 420px;
      width: 700px;
      transition: 0.5s;
      &:hover {
        transform: scale(1.2);
      }
    }
    .right {
      width: 500px;
      height: 500px;
      position: absolute;
      right: 300px;
      top: 400px;
      border: 1px dashed #999999;
      padding: 32px;
      @media only screen and (max-width: 768px) {
        border: 0px;
      }
      .title {
        padding-bottom: 32px;
        border-bottom: 1px solid #cfd1d5;
        margin-bottom: 32px;
      }
      .el-form {
        .el-form-item {
          text-align: left;
          .el-form-item__label {
            font-size: 24px;
          }
          .el-form-item__content {
            .el-input {
              input {
                height: 50px;
                font-size: 18px;
              }
            }
          }
        }
      }
      .sub {
        font-size: 20px;
        margin-top: 60px;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background-color: #366ce0;
        border-radius: 10px;
        color: #fff;
      }
      .sub2 {
        background-color: #96b2f0;
      }
    }
    .yd_right {
      border: 1px dashed #999999;
      margin: 200px;
      padding: 64px 0;
      height: 1300px;
      @media only screen and (min-width: 768px) {
        border: 0px;
        height: 0px;
        margin: 0px;
        padding: 0px;
      }
      .title {
        width: 100%;
        padding-bottom: 32px;
        border-bottom: 1px solid #cfd1d5;
        margin-bottom: 32px;
      }
      .el-form {
        .el-form-item {
          text-align: left;
          .el-form-item__label {
            font-size: 24px;
            margin-bottom: 32px;
            margin-top: 80px;
          }
          .el-form-item__content {
            .el-input {
              input {
                height: 150px;
                font-size: 18px;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      .sub {
        font-size: 20px;
        margin-top: 80px;
        width: 100%;
        height: 150px;
        line-height: 150px;
        background-color: #366ce0;
        border-radius: 10px;
        color: #fff;
      }
      .sub2 {
        background-color: #96b2f0;
      }
    }
  }
}
/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 600px) {
}

/* 小设备 (平板电脑和大型手机，600 像素及以上) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
}

/* 中型设备（平板电脑，768 像素及以上） */
@media only screen and (min-width: 768px) {
}

/* 大型设备（笔记本电脑/台式机，992 像素及以上） */
@media only screen and (min-width: 992px) {
}

/* 超大型设备（大型笔记本电脑和台式机，1200 像素及以上） */
@media only screen and (min-width: 1200px) {
}
</style>
