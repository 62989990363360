<template>
  <div class="home">
    <Banner></Banner>
    <!-- 类名前面的wow是每一个带动画的元素都要加的，
    slideInLeft就是说明动画样式。后面的data-wow-duration（动画持续时间）、
    data-wow-delay（动画延迟时间）、data-wow-offset（元素的位置露出后距离底部多少像素执行）
    和data-wow-iteration（动画执行次数）这四个属性可选可不选。  -->
    <div class="wow slideInLeft" data-wow-duration="0.5s" data-wow-delay="0s" data-wow-offset="10"  data-wow-iteration="1">
      <Page2 :imgObj="page1" :pageType="1"></Page2>
    </div>
    <div class="wow slideInLeft" data-wow-duration="0.5s" data-wow-delay="0s" data-wow-offset="10"  data-wow-iteration="1">
      <Page2 :imgObj="page2" :pageType="2" @gotoForm="goto_form()"></Page2>
    </div>
    <div class="wow slideInLeft" data-wow-duration="0.5s" data-wow-delay="0s" data-wow-offset="10"  data-wow-iteration="1">
      <Page2 :imgObj="page3" :pageType="3" @gotoForm="goto_form()" :bg="bg.bg1"></Page2>
    </div>
    <div class="wow slideInLeft" data-wow-duration="0.5s" data-wow-delay="0s" data-wow-offset="10"  data-wow-iteration="1">
      <Page2 :imgObj="page4" :pageType="4" :bg="bg.bg2"></Page2>
    </div>
    <div ref="form" class="wow slideInLeft" data-wow-duration="0.5s" data-wow-delay="0s" data-wow-offset="10"  data-wow-iteration="1">
      <Page3 :imgObj="page5"></Page3>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Banner from '@/components/banner.vue'
import Page2 from "@/components/page2.vue"
import Page3 from "@/components/page3.vue"

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    Banner,
    Page2,
    Page3
  },
  data() {
    return {
      page1: {
        left: require("../assets/img/2.1.png"),
        right: require("../assets/img/2.2.png")
      },
      page2: {
        left: require("../assets/img/3.1.png"),
        right: require("../assets/img/3.4.png")
      },
      page3: {
        left: require("../assets/img/4.4.png"),
        right: require("../assets/img/4.1.png")
      },
      page4: {
        left: require("../assets/img/5.1.png"),
        right: require("../assets/img/5.4.png")
      },
      page5: {
        left: require("../assets/img/6.4.png"),
        leftIco: require("../assets/img/6.5.png")
      },
      bg: {
        bg1: "#f5f5f5",
        bg2: "#f4fcfa",
      }
    }
  },
  created() {
    // this.get_list();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  },
  methods: {
    goto_form() {
      console.log(this.$refs.form.offsetHeight, '-------------79')
    }
  }
}
</script>
