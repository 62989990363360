<template>
  <div id="app">
    <div class="top">
      <HelloWorld msg="Welcome to Your Vue.js App"/>
    </div>
    <div class="content">
      <router-view/>
    </div>
    <div class="bottom">
      <BottomDb></BottomDb>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import BottomDb from "@/components/bottom.vue"

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    BottomDb
  }
}
</script>

<style lang="scss">
@import url(../src/assets/css/reset.css);
@import url(../src/assets/css/common.scss);
#app {
  font-family: 'HKGrotesk',"微軟正黑體","微软雅黑","黑体","Heiti","华文黑体","ST Heiti",'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 14px;
  color: #333;
  overflow: hidden;
}
.top {
  position: fixed;
  top: 0;
  z-index: 100;
}
.content {
  padding-top: 100px;
}

</style>
