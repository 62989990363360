<template>
  <div class="nav_top">
    <div class="logo">
      <img src="../assets/img/logo.png" alt="天舜科技">
      <div class="h3">天舜科技</div>
    </div>
    <div class="right">
      <div class="h3 gw">
        <a href="https://www.e7wit.com/" class="c7">官网</a>
      </div>
      <div class="h3">{{"免费运营：020-82025252"}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style lang="scss">
.nav_top {
  .c7 {
    color: #333;
  }
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 80px;
  background-color: #fff;
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .gw {
      margin-right: 40px;
    }
    .gw:hover {
      .c7 {
        color: #366ce0;
        cursor: pointer;
      }
    }
  }
}
/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 600px) {
	.nav_top{
		padding: 10px 12px;
    .h3 {
      font-size: 12px;
    }
    img{
		  width: 30px !important;
		  height: 30px !important;
	  }
	}
}

/* 小设备 (平板电脑和大型手机，600 像素及以上) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .nav_top{
		padding: 10px 12px;
    .h3 {
      font-size: 12px;
    }
    img{
		  width: 30px !important;
		  height: 30px !important;
	  }
	}
}

/* 中型设备（平板电脑，768 像素及以上） */
@media only screen and (min-width: 768px) {
  
} 

/* 大型设备（笔记本电脑/台式机，992 像素及以上） */
@media only screen and (min-width: 992px) {
    
} 

/* 超大型设备（大型笔记本电脑和台式机，1200 像素及以上） */
@media only screen and (min-width: 1200px) {
    
}
</style>
