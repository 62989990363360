<template>
  <div class="banner">
    <div class="h2 lh1 text">
      <div>天舜科技</div>
      <div>免费运营</div>
      <div>快速提高企业关注度</div>
      <div>为企业创造巨大商机</div>
    </div>
    <div class="carousel">
      <el-row>
        <el-col :sm="24" class="">
          <el-carousel ref="carousel" :autoplay="autoplay" indicator-position="none">
            <el-carousel-item>
              <img src="../assets/img/1.png" alt="天舜科技代企业运营管理网站" />
            </el-carousel-item>
            <el-carousel-item>
              <img
                src="../assets/img/1.2.png"
                alt="天舜科技免费帮您运营管理企业网站"
              />
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/img/1.png" alt="天舜科技代企业运营管理网站" />
            </el-carousel-item>
            <el-carousel-item>
              <img
                src="../assets/img/1.2.png"
                alt="天舜科技免费帮您运营管理企业网站"
              />
            </el-carousel-item>
          </el-carousel>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    msg: String,
  },
  mounted() {
    this.slideBanner()
  },
  data() {
    return {
      imgList: [""],
    };
  },
  methods: {
    //是否自动播放
    stopAuto() {
      this.autoplay = false;
    },
    startAuto() {
      this.autoplay = true;
    },
    // 轮播手滑切换
    slideBanner() {
      let that = this;
      //选中item的盒子
      var box = document.querySelector(".el-carousel__container");
      //手指起点X坐标
      var startPoint = 0;
      //手指滑动重点X坐标
      var stopPoint = 0;

      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };

      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指按下的时候停止自动轮播
        that.stopAuto();
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          that.startAuto();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.carousel.next();
          that.startAuto();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.carousel.prev();
          that.startAuto();
          return;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.banner {
  .text {
    height: 320px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
  .el-carousel {
    height: 900px;
    .el-carousel__container {
      height: 900px;
    }
  }
  .el-carousel__item {
    height: 900px;
  }
  img {
    width: 100%;
  }
}
/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 600px) {
  .banner {
    .text {
      padding: 32px 20px;
      height: 150px;
    }
  }
}

/* 小设备 (平板电脑和大型手机，600 像素及以上) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
}

/* 中型设备（平板电脑，768 像素及以上） */
@media only screen and (min-width: 768px) {
}

/* 大型设备（笔记本电脑/台式机，992 像素及以上） */
@media only screen and (min-width: 992px) {
}

/* 超大型设备（大型笔记本电脑和台式机，1200 像素及以上） */
@media only screen and (min-width: 1200px) {
}
</style>
