<template>
  <div class="bottom">
    <div class="last-bottom padding-bottom-sm">
      <div class="dis-flex just-betw padding-top-sm">
        <div class="dis-flex common-bot">
          <div class="dis-flex margin-right">
            <div class="dis-flex ioc_phone" id="show-qrcode">
              <div class="dis-flex margin-right phone-logo">
                <div class="dis-flex ico">
                  <img class="logo" src="../assets/img/logo4.png" alt="logo" />
                  <div class="tianshun">天舜科技</div>
                </div>
                <div class="dis-flex phone">
                  <img
                    src="../assets/img/phone2.png"
                    alt=""
                    class="margin-right-xs min-phone margin-left-xs"
                  />
                  <div class="h3 c_red font-wei-600 nowrap">020-82025252</div>
                </div>
              </div>
              <div class="dis-flex code" style="flex-direction: column">
                <img src="../assets/img/qr_coce.jpg" alt="" class="qr-bot" />
                <div class="c7 h4 margin-top-sm nowrap">
                  微信扫一扫，免费帮你运营
                </div>
              </div>
            </div>
          </div>
          <div class="dis-flex just-betw linkList">
            <ul class="margin-right-xs">
              <li class="h3 c7 margin-bottom-sm nowrap">
                <a class="c7" href="https://www.e7wit.com/website.html">网站地图</a>
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a href="https://www.e7wit.com/index.html" class="c7 h4 tab_a nowrap">网站首页</a>
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a href="https://www.e7wit.com/product.html" class="c7 h4 tab_a nowrap">产品介绍</a>
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a href="https://www.e7wit.com/company_solu.html" class="c7 h4 tab_a nowrap"
                  >行业方案</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a href="https://www.e7wit.com/company_information.html" class="c7 h4 tab_a nowrap"
                  >企业资讯</a
                >
              </li>
              <li class="h4 c7">
                <a href="https://www.e7wit.com/cooperate_way.html" class="c7 h4 tab_a nowrap"
                  >合作渠道</a
                >
              </li>
            </ul>
            <ul class="margin-right-xs">
              <li class="h3 c7 margin-bottom-sm nowrap">服务中心</li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/service_cen.html?tabtype=tab_up"
                  class="c7 h4 tab_a nowrap"
                  >升级服务</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/service_cen.html?tabtype=tab_on"
                  class="c7 h4 tab_a nowrap"
                  >在线化服务</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/service_cen.html?tabtype=tab_dev"
                  class="c7 h4 tab_a nowrap"
                  >定制开发</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/service_cen.html?tabtype=tab_twice"
                  class="c7 h4 tab_a nowrap"
                  >二次开发</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/service_cen.html?tabtype=tab_way"
                  class="c7 h4 tab_a nowrap"
                  >实施方法</a
                >
              </li>
              <li class="h4 c7">
                <a
                  href="https://www.e7wit.com/service_cen.html?tabtype=tab_step"
                  class="c7 h4 tab_a nowrap"
                  >实施步骤</a
                >
              </li>
            </ul>
            <ul class="margin-right-xs">
              <li class="h3 c7 margin-bottom-sm nowrap">关于我们</li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/new_about_us.html?tabtype=tab_intro"
                  class="c7 h4 tab_a nowrap"
                  >公司简介</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/new_about_us.html?tabtype=tab_cer"
                  class="c7 h4 tab_a nowrap"
                  >公司资质</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/new_about_us.html?tabtype=tab_service"
                  class="c7 h4 tab_a nowrap"
                  >企业文化</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a
                  href="https://www.e7wit.com/new_about_us.html?tabtype=tab_conact_us"
                  class="c7 h4 tab_a nowrap"
                  >联系我们</a
                >
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a href="https://www.e7wit.com/pravite.html" class="c7 h4 tab_a nowrap">隐私政策</a>
              </li>
              <li class="h4 c7 margin-bottom-xs">
                <a href="https://www.e7wit.com/service-text.html" class="c7 h4 tab_a nowrap"
                  >服务协议</a
                >
              </li>
            </ul>
            <ul class="margin-right-xs friendlink1" v-if="linkList[0] && linkList[0].length !=0">
              <li class="h3 c7 margin-bottom-sm nowrap">友情链接</li>
              <li class="h4 c7 margin-bottom-xs" v-for="(l, i) in linkList[0]" :key="i">
                <a :href="l.link_url" class="c7 h4 tab_a nowrap"
                  >{{ l.link_name }}</a
                >
              </li>
            </ul>
            <ul class="margin-right-xs friendlink2" v-if="linkList[1] && linkList[1].length !=0">
              <li class="h3 c7 margin-bottom-sm nowrap">网址</li>
              <li class="h4 c7 margin-bottom-xs" v-for="(l, i) in linkList[1]" :key="i">
                <a :href="l.link_url" class="c7 h4 tab_a nowrap"
                  >{{ l.link_name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="h4 bot-title margin-top">
        <div class="lh1 h4 c7">广州市黄埔区科学城瑞和路79号三楼312-313</div>
        <div class="lh1 h4 c7">&nbsp;&nbsp;|</div>
        <div class="lh1 h4 c7">
          &nbsp;&nbsp;广东天舜信息科技有限公司 版权所有
        </div>
        <div class="lh1 h4 c7">&nbsp;&nbsp;|</div>
        <a class="lh1 h4 c7" href="https://beian.miit.gov.cn" target="_blank"
          >&nbsp;&nbsp;粤ICP备16025211号-6</a
        >
        <div class="lh1">&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div class="gan">
          <img class="gaimg" src="../assets/img/gaba.png" alt="" />
          <a
            class="h4 c7"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202002240"
            target="_blank"
            >&nbsp;粤公网安备 44011202002240号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "bottom",
  props: {
    msg: String,
  },
  data() {
    return {
      imgList: [""],
      linkList: [],
    };
  },
  created() {
    this.getLink()
  },
  methods: {
    getLink() {
      axios.get("https://www.e7wit.com/sys/info/getFriendlinkList").then((res) => {
        if(res && res.status == 200) {
          res.data.forEach((i)=>{
            this.linkList.push(i.data)
          })
        }
        console.log(this.linkList)
      })
    }
  }
};
</script>

<style lang="scss">
.bottom {
.c7{
	color: #ffffff;
}

.last-bottom {
	/* height: 7.29vw; */
	width: 100%;
	background: #333333;
}
/* 用的 */
.dis-flex{
	display: flex;
}
.just-betw{
	justify-content: space-between;
}
.padding{
	padding:12px;
}
.padding-sm{
	padding:24px;
}
.padding-top-sm{
	padding-top:24px;
}
.padding-bottom-sm{
	padding-bottom:24px;
}
.margin-right-xs{
	margin-right:12px;
}
.margin-right{
	margin-right:36px;
}
.margin-left-xs{
	margin-left:12px;
}
.margin-top-sm{
	margin-top:24px;
}
.margin-top{
	margin-top: 12px;
}
.margin-bottom-xs{
	margin-bottom:12px;
}
.margin-bottom-sm{
	margin-bottom:12px;
}
.common-bot{
	width: 70%;
    margin: 0 auto;
    @media only screen and (max-width: 800px) {
        display: block;
        width: 90%;
        margin-top: 24px;
        .margin-right {
          justify-content: center;
          .phone-logo {
            margin-right: 20px;
            .ico {
              margin-bottom: 10px;
            }
            .logo {
              width: 60px;
            }
            .tianshun {
              font-size: 14px;
            }
            .phone {
                img {
                  width: 14px; 
                  height: 14px;
                  margin-right: 5px;
                }
                .c_red {
                    font-size: 14px;
                }
            }
          }
        }
    }
}
.linkList {
    width: 100%;
    justify-content: space-around;
    ul {
        li {
            text-align: left;
        }
    }
    .h3 {
        font-size: 20px;
    }
    .h4 {
        font-size: 16px;
    }
    @media only screen and (max-width: 800px) {
        display: none;
        margin-top: 24px;
        width: 100%;
    }
}
.ioc_phone {
  align-items: center;
  margin-bottom: 45px;
}
.phone-logo{
	flex-direction: column!important;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 30px;
    .ico {
      align-items: center;
      margin-bottom: 10px;
    }
    .logo {
      width: 80px;
    }
    .tianshun {
      font-size: 24px;
      color: #fff;
    }
}
.code {
    align-items: center;
    @media only screen and (max-width: 800px) {
        .h4 {
            font-size: 14px;
        }
    }
}
.min-phone{
	width: 24px;
	height: 24px;
}
.c_red{
    color: #e60012;
}
.font-wei-600{
	font-weight: 600;
}
.nowrap{
	white-space: nowrap;
}
.qr-bot{
	width: 150px;
	height: 150px;
	object-fit: scale-down;
    @media only screen and (max-width: 800px) {
        width: 150px;
        height: 150px;
        margin-bottom: 12px;
    }
}
.tab_a:focus{
	text-decoration-line: none;
    color: #ff0000 !important;
}
.bot-title{
	display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (max-width: 800px) {
        padding: 20px;
        .h4 {
            font-size: 14px !important;
        }
    }
}
.gan{
    display: flex;
    align-items: center;
}
}
/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 600px) {
  
}

/* 小设备 (平板电脑和大型手机，600 像素及以上) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
}

/* 中型设备（平板电脑，768 像素及以上） */
@media only screen and (min-width: 768px) {
}

/* 大型设备（笔记本电脑/台式机，992 像素及以上） */
@media only screen and (min-width: 992px) {
}

/* 超大型设备（大型笔记本电脑和台式机，1200 像素及以上） */
@media only screen and (min-width: 1200px) {
}
</style>
