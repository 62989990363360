import { get, post } from './http'

// 活动报名
export function applyData(data) {
    return post('/crm/ActivityApi/enroll', data)
}


// 底部链接
export function linkList(data) {
    return post('/sys/info/getFriendlinkList', data)
}

export default {
	applyData,
    linkList
}
